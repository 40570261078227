import React from 'react'
import { styled } from '@mui/material/styles'
import { withStyles } from '@material-ui/core/styles'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const styles = {
  paperRoot: {
    boxShadow:
      '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important',
    borderRadius: '15px !important',
    position: 'relative'
  },
  MuiTypographyRoot: {
    marginBottom: '0 !important',
    fontWeight: '600 !important',
    fontSize: '16px !important'
  }
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

const CardComponent = ({ classes, ...props }) => {
  return (
    <Card classes={{ root: classes.paperRoot }} sx={{ maxWidth: '100%' }}>
      <CardMedia
        component='img'
        height='223'
        image={props.image}
        alt={props.alt}
        sx={{borderRadius: 2}}
      />

      <CardContent style={{ marginBottom: '-58px' }}>
        <h4 className='headingH4'>{props.heading}</h4>
      </CardContent>

      <CardActions disableSpacing>
        <ExpandMore
          expand={props.expand}
          onClick={props.onClick}
          aria-expanded={props.aria}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={props.collapse} timeout='auto' unmountOnExit>
        <CardContent className='Card__content'>
          <Typography paragraph classes={{ root: classes.MuiTypographyRoot }}>
            {props.content}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default withStyles(styles)(CardComponent)
