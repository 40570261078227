import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';

import './App.scss'
import Menu from './components/Menu'
// import ChatBot from './components/ChatBot'
import Home from './pages/Home/Home'
import Services from './pages/Services/Services'
import Careers from './pages/Careers/Careers'
import CareersApplyNow from "./pages/Careers/CareersApplyNow"
import About from './pages/About/About'
import FAQ from './pages/FAQ/FAQ'
import WhatsNew from './pages/WhatsNew/WhatsNew'
import Articles from './pages/Articles/Articles'
import ContactUs from './pages/ContactUs/ContactUs'
import Footer from './pages/Footer/Footer'

const Layout = () => {
  return (
    <div className='App'>
      <SnackbarProvider preventDuplicate>
        <Menu />
        <Home />
        <Services />
        <About />
        <FAQ />
        <WhatsNew />
        <Articles />
        <Careers />
        <ContactUs />
        <Footer />
        {/* <ChatBot /> */}
      </SnackbarProvider>
    </div>
  )
}

function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} />
        <Route path="/careersapplynow" element={<CareersApplyNow />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
