import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useSnackbar } from 'notistack';

import Config from '../../config/config.json'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { ReactComponent as Icon } from '../../assets/images/svg/contact.svg'

const ContactUs = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    message: ""
  })

  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    emailjs.sendForm(Config.email_service_id, Config.email_template_id, form.current, Config.email_pub)
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent")
          setFormValues({
            name: "",
            email: "",
            mobile: "",
            message: ""
          })
          enqueueSnackbar("Message Sent", { variant: 'success' })
      }, (error) => {
          console.log(error.text)
      })
  };
  return (
    <div className='ContactUs' id='contact'>
      <h2 className='headingH2'>
        <Icon className='icon' />
        Contact Us
      </h2>

      <div className='ContactUs__item'>
        <form ref={form} onSubmit={sendEmail}>
          <TextField
            id='outlined'
            placeholder='Name'
            multiline maxRows={4}
            name="user_name"
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
          <TextField
            id='outlined'
            placeholder='Email Address'
            multiline
            maxRows={4}
            name="user_email"
            value={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
          />
          <TextField
            id='outlined'
            placeholder='Contact Number'
            multiline
            maxRows={4}
            name="user_mobile"
            value={formValues.mobile}
            onChange={(e) =>
              setFormValues({ ...formValues, mobile: e.target.value })
            }
          />
          <TextField
            id='outlined'
            multiline
            rows={4}
            placeholder='Write your Message'
            name="message"
            value={formValues.message}
            onChange={(e) =>
              setFormValues({ ...formValues, message: e.target.value })
            }
          />
          <Button type="submit">Send Message</Button>
        </form>
        <iframe
          title='geojet-map2023'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.863569765815!2d121.01072707476553!3d14.549793385930528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c905a8a30a13%3A0xba903387135be43a!2sGeojet%20Logistics%20Services%2C%20Inc.!5e0!3m2!1sen!2sph!4v1681537007081!5m2!1sen!2sph'
          style={{ border: '0', width: '100%', height: 581 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        />
      </div>
    </div>
  )
}

export default ContactUs
