import React, { useRef } from 'react'

import { ReactComponent as Icon } from '../../assets/images/svg/about-us.svg'
import Video from '../../assets/video/Geojet-infographics.mp4'
import videoImg from '../../assets/video/videoImg.png'
import AboutCard from '../../components/AboutCard'

const About = () => {
  const videoRef = useRef(null)

  const handleLoadedData = () => videoRef.current.setAttribute('poster', videoImg)

  return (
    <div className='About' id='aboutus'>
      <div className='About__container'>
        <div className='About__item'>
          <video
            ref={videoRef}
            controls
            width='100%'
            height='400'
            onLoadedData={handleLoadedData}
          >
            <source type='video/mp4' src={Video} />
          </video>
        </div>

        <div className='About__item'>
          <h2 className='headingH2'>
            <Icon className='icon' />
            About Us
          </h2>
          <div className='About__item--content'>
            <p>
              For more than 20 years, we have provided one of the most reliable
              logistics solutions in the Philippines – with services ranging from land, air, and sea transport as well as warehousing. We work with big and small companies to help them reach their goals and capture a bigger market in the Asian region and around the world.
            </p>
          </div>
        </div>
      </div>

      <div className='About__card'>
        <AboutCard start={0} end={20} title='Years of Experience' />
        <AboutCard start={0} end={300} title='Companies' />
        <AboutCard start={0} end={108} title='Countries' />
        <AboutCard start={0} end={1500} title='Couriers' />
      </div>
    </div>
  )
}

export default About
