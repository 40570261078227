import React from 'react'
import CountUp from 'react-countup'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

const AboutCard = (props) => {
  return (
    <Card sx={{ maxWidth: '100%' }} className='Card'>
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          <CountUp start={props.start} end={props.end} />
          <span>+</span>
        </Typography>
        <Typography variant='body2'>{props.title}</Typography>
      </CardContent>
    </Card>
  )
}

export default AboutCard
