import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "../../assets/images/svg/careers.svg";
import CareerImg from "../../assets/images/careers/Careers.jpg";

import Button from "@mui/material/Button";

const Careers = () => {
  return (
    <div className="Careers" id="careers">
      <div className="Careers__container">
        <div className="Careers__item">
          <img src={CareerImg} alt="CareerImg" />
        </div>

        <div className="Careers__item">
          <h2 className="headingH2">
            <Icon className="icon" />
            Careers
          </h2>
          <div className="Careers__item--content">
            <h3>We are Hiring!</h3>
            <p>We need professionals to fill in particular positions.</p>
            <Button component={Link} to='/careersapplynow' target='_blank' variant="contained">
              Apply Now!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
