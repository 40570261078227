import React, { useState, useEffect } from "react";

import Logo from "../../assets/images/logo/logo.png";
import { ReactComponent as Icon } from "../../assets/images/svg/careers.svg";
import Telemarketer from "../../assets/images/careers/telemarketer.jpg";
import CostAnalyst from "../../assets/images/careers/costanalyst.jpg";
import CSR from "../../assets/images/careers/csr.jpg";

import Footer from "../Footer/Footer";

const CareersApplyNow = () => {
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  return (
    <>
      <div className={`Menu ${stickyClass}`}>
        <div className="Menu__items">
          <nav>
            <a href="/">
              <img src={Logo} alt="logo" />
            </a>
          </nav>
        </div>
      </div>
      <div className="CareersApplyNow">
        <div className="CareersApplyNow__header">
          <h2 className="headingH2">
            <Icon className="icon" />
            Careers
          </h2>
          <h3>We are looking for these positions</h3>
          <p>
            Kindly send your CV about
            <a href="mailto: aljon.buna@geojet.com.ph">
              aljon.buna@geojet.com.ph
            </a>
          </p>
        </div>

        <div className="CareersApplyNow__items">
          <div className="item">
            <img src={Telemarketer} alt="Telemarketer" />
          </div>
          <div className="item">
            <h4>Telemarketer</h4>
            <div className="content">
              <h5>Job Description: </h5>
              <ul className="jobdescription">
                <li>
                  Reach out to existing and potential customers to present our
                  product and service offering
                </li>
                <li> Learn details about our service offerings</li>
                <li> Address any questions or issues customers may have</li>
                <li>
                  Communicate with customers to understand their requirements
                  and and need
                </li>
                <li>Offer solutions based on clients needs and capabilities</li>
                <li>
                  Direct prospects and leads to the sales team when needed
                </li>
                <li> Keep an updated customer database</li>
                <li> Keep records of calls and sales</li>
              </ul>
              <h5>Additional Information </h5>
              <ul className="additionalinfo">
                <li>
                  <b>Career Level</b>
                  <br />
                  <span>Less than 1 Year Experienced Employee</span>
                </li>
                <li>
                  <b> Qualification</b>
                  <br />
                  <span>Bachelor's/College Degree</span>
                </li>
                <li>
                  <b> Job Type</b>
                  <br /> <span>Full-Time</span>
                </li>
                <li>
                  <b> Job Specializations</b>
                  <br />
                  <span>Sales/Marketing, Telesales/Telemarketing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="CareersApplyNow__items">
          <div className="item">
            <img src={CostAnalyst} alt="Cost Analyst" />
          </div>
          <div className="item">
            <h4>Cost Analyst</h4>
            <div className="content">
              <h5>Job Description: </h5>
              <ul className="jobdescription">
                <li>
                  The Cost Analyst will collect and analyze rates related to the
                  shipment cost, seeking ways to improve accuracy of income and
                  forecasts, and to optimize the cost of transactions and
                  shipments.
                </li>
                <li>
                  Analyzes expenses and cost transactions; ensures that cost
                  rates are competitive.
                </li>
                <li>
                  Conducts market research as needed to recommend changes to
                  pricing.
                </li>
                <li>
                  Prepares periodic cost reconciliation reports tracking data
                  such as changing rates and supply costs.
                </li>
                <li>Performs other related duties, as assigned.</li>
              </ul>
              <h5>Additional Information </h5>
              <ul className="additionalinfo">
                <li>
                  <b>Career Level</b>
                  <br />
                  <span>Less than 1 Year Experienced Employee</span>
                </li>
                <li>
                  <b> Qualification</b>
                  <br />
                  <span>Bachelor's/College Degree</span>
                </li>
                <li>
                  <b> Job Type</b>
                  <br /> <span>Full-Time</span>
                </li>
                <li>
                  <b> Job Specializations</b>
                  <br />
                  <span>Sales/Marketing, Telesales/Telemarketing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="CareersApplyNow__items">
          <div className="item">
            <img src={Telemarketer} alt="Telemarketer" />
          </div>
          <div className="item">
            <h4>Telemarketer (CEBU BASED)</h4>
            <div className="content">
              <h5>Job Description: </h5>
              <ul className="jobdescription">
                <li>
                  Reach out to existing and potential customers to present our product and service offering
                </li>
                <li> Learn details about our service offerings</li>
                <li> Address any questions or issues customers may have</li>
                <li>
                  Communicate with customers to understand their requirements
                  and and need
                </li>
                <li>Offer solutions based on clients needs and capabilities</li>
                <li>
                  Direct prospects and leads to the sales team when needed
                </li>
                <li> Keep an updated customer database</li>
                <li> Keep records of calls and sales</li>
              </ul>
              <h5>Additional Information </h5>
              <ul className="additionalinfo">
                <li>
                  <b>Career Level</b>
                  <br />
                  <span>Less than 1 Year Experienced Employee</span>
                </li>
                <li>
                  <b> Qualification</b>
                  <br />
                  <span>Bachelor's/College Degree, must able to speak Cebuano</span>
                </li>
                <li>
                  <b> Job Type</b>
                  <br /> <span>Full-Time</span>
                </li>
                <li>
                  <b> Job Specializations</b>
                  <br />
                  <span>Sales/Marketing, Telesales/Telemarketing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="CareersApplyNow__items">
          <div className="item">
            <img src={CSR} alt="CSR" />
          </div>
          <div className="item">
            <h4>Consolidation Customer Service (Manila Based)</h4>
            <div className="content">
              <h5>Job Description: </h5>
              <ul className="jobdescription">
                <li>
                  Develop and maintain strong relationships with suppliers, carriers, and customers to ensure the smooth flow of goods
                </li>
                <li>
                  Coordinate and plan shipments to optimize efficiency and minimize costs
                </li>
                <li>
                  Track and monitor shipments to ensure on-time delivery and resolve any issues that arise
                </li>
                <li>
                  Generate reports and analyze data to identify areas for improvement in the consolidation process
                </li>
                <li>
                  Ensure compliance with all regulatory requirements and industry standards
                </li>
                <li>
                  Collaborate with other teams, such as sales and customer service, to ensure customer satisfaction
                </li>
                <li>
                  Manage inventory levels and make recommendations for replenishment
                </li>
                <li>
                  Stay up-to-date with industry developments and market trends to remain competitive
                </li>

              </ul>
              <h5>Additional Information </h5>
              <ul className="additionalinfo">
                <li>
                  <b>Career Level</b>
                  <br />
                  <span>Stay up-to-date with industry developments and market trends to remain competitive</span>
                </li>
                <li>
                  <b> Qualification</b>
                  <br />
                  <span>Bachelor's/College Degree</span>
                </li>
                <li>
                  <b> Job Type</b>
                  <br /> <span>Full-Time</span>
                </li>
                <li>
                  <b> Job Specializations</b>
                  <br />
                  <span>Services, Logistics/Supply Chain</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default CareersApplyNow;
