import React from 'react'
import Banner from '../../assets/images/banner.png'

const Home = () => {
  return (
    <div className='Home' id='home'>
      <img src={Banner} alt='Banner' />
      <div className='header'>
        <h3>WE DELIVER YOUR CARGO</h3>
        <h2>
          <span>SAFE</span>
          <span>SWIFT</span>
          <span>SECURE</span>
        </h2>

      </div>
    </div>
  )
}

export default Home
