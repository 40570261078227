import * as React from 'react'

import { ReactComponent as Icon } from '../../assets/images/svg/frequently-asked-questions.svg'
import Accordion from '../../components/Accordion'

const FAQ = () => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div className='FAQ' id='faq'>
      <h2 className='headingH2'>
        <Icon className='icon' />
        Frequently Asked Questions
      </h2>
      <div className='FAQ__item'>
        <Accordion
          aria='panel1a-content'
          id='panel1a-header'
          title='If I need to ship by a certain date or need cargo to arrive by a certain date, what are my best options?'
          summary='If you have urgent cargo, please let GEOJET know immediately so they can plan for it – the best option for urgent cargo is to ship it via Air. Sometimes, it may be worth it to pay a little bit more to get faster transit or a direct service for better reliability.'
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        />
        <Accordion
          aria='panel2a-content'
          id='panel2a-header'
          title='How quickly can I get a quote?'
          summary='Getting a quote vary depending on many different factors including; origin/destination and relevant time differences to request rates, goods ready date, departure date, carrier service and requirements, the volume of shipment and others.  For Example, if you need rates from Intra-Asia, GEOJET can provide the rates within a short period of time (within the day of request).'
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        />

        <Accordion
          aria='panel3a-content'
          id='panel3a-header'
          title='Can you help me with customs clearance?'
          summary='Yes, GEOJET have an in-house broker that can help you with the customs processing and releasing of your cargo.'
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        />

        <Accordion
          aria='panel4a-content'
          id='panel4a-header'
          title='How long have you been in operation?'
          summary="GEOJET providing one of the most reliable logistics solutions in the Philippines for 22 years already, with services ranging from land, air and sea transport."
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        />

        <Accordion
          aria='panel5a-content'
          id='panel5a-header'
          title='How qualified is your staff?'
          summary='We assure GEOJET has dedicated and experienced staffs that are trained to provide accurate shipment details and documentation assistance.'
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        />
      </div>
    </div>
  )
}

export default FAQ
