import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

const styles = {
  paperRoot: {
    boxShadow:
      '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important',
    borderRadius: '15px !important'
  },
  MuiTypographyRoot: {
    fontWeight: '700 !important',
    fontSize: '16px !important'
  }
}

const CardComponent = ({ classes, ...props }) => {
  return (
    <Card
      className='Card'
      classes={{ root: classes.paperRoot }}
      sx={{ height: '100%' }}
    >
      <CardMedia
        component='img'
        height='244'
        image={props.image}
        alt={props.alt}
      />

      <CardContent className='Card__content'>
        <h4 className='headingH4'>{props.heading}</h4>

        <Typography paragraph classes={{ root: classes.MuiTypographyRoot }}>
          {props.content}
        </Typography>

        <CardActions className='Card__actions' disableSpacing>
          <p>{props.date}</p>

          <Link to={props.url} target='_blank'>
            Read more from source
          </Link>
        </CardActions>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(CardComponent)
