import React, { useEffect, useState } from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Button from '@mui/material/Button'
import Slider from 'react-slick'

import { ReactComponent as Icon } from '../../assets/images/svg/articles.svg'
import ArticleCard from '../../components/ArticleCard'

const Arrow = ({ className, style, onClick }) => (
  <Button
    style={{ ...style, left: 0 }}
    onClick={onClick}
    className={className}
  />
)

const ArrowRight = ({ className, style, onClick }) => (
  <Button
    style={{ ...style, right: 0 }}
    onClick={onClick}
    className={className}
  />
)

const Articles = () => {
  const [art, setArt] = useState([])
  const fetchArticlesData = () => {
    fetch("https://api.geojet.com.ph/articles")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setArt(data)
      })
  }

  useEffect(() => {
    fetchArticlesData()
  }, [])
  return (
    <div className='Articles' id='articles'>
      <h2 className='headingH2'>
        <Icon className='icon' />
        Articles
      </h2>

      <div className='Articles__item'>
        <Slider
          className='slick-track'
          slidesToShow={3}
          slidesToScroll={3}
          autoplay
          autoplaySpeed={3000}
          prevArrow={<Arrow />}
          nextArrow={<ArrowRight />}
          arrows
          lazyLoad
          height='100%'
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}
        >
          {
            art.map(article => (
              <ArticleCard
                image={article.img_url}
                alt={article.title}
                heading={article.title.slice(0,40)+'. . .'}
                content={article.content.slice(0,200)+'. . .'}
                date={article.article_date}
                url={article.url}
              />
            ))
          }
        </Slider>
      </div>
    </div>
  )
}

export default Articles
