import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const AccordionComponent = (props) => {
  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
        aria-controls={props.aria}
        id={props.id}
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.summary}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionComponent
