import React, { useEffect, useState } from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Button from '@mui/material/Button'
import Slider from 'react-slick'

import { ReactComponent as Icon } from '../../assets/images/svg/whats-new.svg'
import WhatsNewCard from '../../components/WhatsNewCard'

const Arrow = ({ className, style, onClick }) => (
  <Button
    style={{ ...style, left: 0 }}
    onClick={onClick}
    className={className}
  />
)

const ArrowRight = ({ className, style, onClick }) => (
  <Button
    style={{ ...style, right: 0 }}
    onClick={onClick}
    className={className}
  />
)

const WhatsNew = () => {
  const [whatsNew, setWhatsNew] = useState([])
  const fetchWhatNewData = () => {
    fetch("https://api.geojet.com.ph/whatsnew")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setWhatsNew(data)
      })
  }

  useEffect(() => {
    fetchWhatNewData()
  }, [])
  return (
    <div className='WhatsNew' id='whatsnew'>
      <h2 className='headingH2'>
        <Icon className='icon' />
        What's New?
      </h2>

      <div className='WhatsNew__item'>
      <Slider
        className='slick-track'
        slidesToShow={3}
        slidesToScroll={3}
        autoplay
        autoplaySpeed={3000}
        prevArrow={<Arrow />}
        nextArrow={<ArrowRight />}
        arrows
        lazyLoad
        height='100%'
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]}
      >
        {
          whatsNew.map(wnew => (
            <WhatsNewCard
              image={wnew.img_url}
              alt={wnew.title}
              heading={wnew.title.slice(0,40)+'. . .'}
              content={wnew.content.slice(0,150)+'. . .'}
              url={wnew.url}
            />
          ))
        }
      </Slider>
      </div>
    </div>
  )
}

export default WhatsNew
