import React, { useState } from 'react'

import LCL from '../../assets/images/services/1-lcl-consolidation.jpg'
import FCL from '../../assets/images/services/2-fcl-shipment.jpg'
import SPS from '../../assets/images/services/3-special-project-shipment.jpg'
import TSADD from '../../assets/images/services/4-trucking-service-and-domestic-delivery.jpg'
import CC from '../../assets/images/services/5-custom-clearance.jpg'
import Warehouse from '../../assets/images/services/6-warehousing.jpg'
import Packing from '../../assets/images/services/7-packing.jpg'
import MI from '../../assets/images/services/8-marine-insurance.jpg'
import Fumigation from '../../assets/images/services/9-fumigation.jpg'

import { ReactComponent as Icon } from '../../assets/images/svg/what-we-offer.svg'

import CardComponent from '../../components/ServicesCard'

const Services = () => {
  const [lcl, setLcl] = useState(false)
  const [fcl, setFcl] = useState(false)
  const [sps, setSps] = useState(false)
  const [tsadd, setTsadd] = useState(false)
  const [cc, setCc] = useState(false)
  const [warehouse, setWarehouse] = useState(false)
  const [packing, setPacking] = useState(false)
  const [mi, setMi] = useState(false)
  const [fumigation, setFumigation] = useState(false)

  const handleExpandlcl = () => {
    setLcl(!lcl)
    setFcl(false)
    setSps(false)
    setTsadd(false)
    setCc(false)
    setWarehouse(false)
    setPacking(false)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandfcl = () => {
    setLcl(false)
    setFcl(!fcl)
    setSps(false)
    setTsadd(false)
    setCc(false)
    setWarehouse(false)
    setPacking(false)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandsps = () => {
    setLcl(false)
    setFcl(false)
    setSps(!sps)
    setTsadd(false)
    setCc(false)
    setWarehouse(false)
    setPacking(false)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandtsadd = () => {
    setLcl(false)
    setFcl(false)
    setSps(false)
    setTsadd(!tsadd)
    setCc(false)
    setWarehouse(false)
    setPacking(false)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandcc = () => {
    setLcl(false)
    setFcl(false)
    setSps(false)
    setTsadd(false)
    setCc(!cc)
    setWarehouse(false)
    setPacking(false)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandWarehouse = () => {
    setLcl(false)
    setFcl(false)
    setSps(false)
    setTsadd(false)
    setCc(false)
    setWarehouse(!warehouse)
    setPacking(false)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandPacking = () => {
    setLcl(false)
    setFcl(false)
    setSps(false)
    setTsadd(false)
    setCc(false)
    setWarehouse(false)
    setPacking(!packing)
    setMi(false)
    setFumigation(false)
  }

  const handleExpandmi = () => {
    setLcl(false)
    setFcl(false)
    setSps(false)
    setTsadd(false)
    setCc(false)
    setWarehouse(false)
    setPacking(false)
    setMi(!mi)
    setFumigation(false)
  }

  const handleExpandFumigation = () => {
    setLcl(false)
    setFcl(false)
    setSps(false)
    setTsadd(false)
    setCc(false)
    setWarehouse(false)
    setPacking(false)
    setMi(false)
    setFumigation(!fumigation)
  }

  return (
    <div className='Services' id='services'>
      <div className='Services__item'>
        <h2 className='headingH2'>
          <Icon className='icon' />
          What We Offer?
        </h2>
        <div className='Services__item--content'>
          <p>
            We offer a one stop shop of logistics services designed to meet your every requirement.
            Our logistics specialist will ensure that everything runs smoothly from the time you give us a call until your cargo reaches its destination.
            Our aim is to be your reliable partner in the growth of your business.
          </p>
        </div>
      </div>
      <div className='Services__item'>
        <CardComponent
          image={LCL}
          alt='LCL CONSOLIDATION & 
          DECONSOLIDATION'
          heading='LCL CONSOLIDATION & 
          DECONSOLIDATION'
          content='No cargo is too big or small for us to handle. For less 
          than container load (LCL) shipments, you don’t have 
          to pay for the entire container van cost and its space 
          that you don’t need. 
          Just hand to us your goods and we will make sure 
          they arrive to their point of destination on time - at a 
          fraction of the cost.'
          onClick={handleExpandlcl}
          expand={lcl}
          aria={lcl}
          collapse={lcl}
        />

        <CardComponent
          image={FCL}
          alt='FCL SHIPMENTS'
          heading='FCL SHIPMENTS'
          content='For full container load (FCL) shipments, we will arrange 
          for container vans that you can fill up to the brim with 
          your precious goods.
          Just tell us what you want for your goods and we will 
          identify the best shipping service for your 
          requirements. Wherever you need these cargoes 
          delivered, we got them covered - safe and on time.'
          onClick={handleExpandfcl}
          expand={fcl}
          aria={fcl}
          collapse={fcl}
        />

        <CardComponent
          image={SPS}
          alt='SPECIAL / PROJECT
          SHIPMENTS'
          heading='SPECIAL / PROJECT
          SHIPMENTS'
          content='For cargoes that require unconventional handling,
          heavy lifts, special equipments and for goods with 
          odd-sizes and irregular shapes, GEOJET can move 
          and deliver even to the most remote places. 
          Whether piece-by-piece or the whole of it, your 
          goods are handled - carefully and efficiently.'
          onClick={handleExpandsps}
          expand={sps}
          aria={sps}
          collapse={sps}
        />

        <CardComponent
          image={TSADD}
          alt='TRUCKING SERVICE & DOMESTIC DELIVERIES'
          heading='TRUCKING SERVICE & DOMESTIC DELIVERIES'
          content='We can provide you with the hauling and delivery 
          service you need from and to any point in the 
          Philippines.
          From key cities to the islands, your goods will be 
          delivered - on the dot.'
          onClick={handleExpandtsadd}
          expand={tsadd}
          aria={tsadd}
          collapse={tsadd}
        />

        <CardComponent
          image={CC}
          alt='CUSTOM CLEARANCE'
          heading='CUSTOM CLEARANCE'
          content='We do more than just shipping! Our logistics specialist 
          can clear your goods from the Bureau of Customs 
          while you attend to more important things.
          We’ll take care of the document preparation and make 
          sure that your shipment does not acquire additional 
          cost. We provide you with timely updates, deliver your 
          cargo the soonest time possible with an accurate and 
          above board documentation.'
          onClick={handleExpandcc}
          expand={cc}
          aria={cc}
          collapse={cc}
        />

        <CardComponent
          image={Warehouse}
          alt='WAREHOUSING & STORAGE'
          heading='WAREHOUSING & STORAGE'
          content='We offer you solutions to your storage space needs. Our 
          warehouse facilities are strategically located.
          With our round-the-clock security, you can be sure that 
          your merchandise are kept - intact and secured.'
          onClick={handleExpandWarehouse}
          expand={warehouse}
          aria={warehouse}
          collapse={warehouse}
        />

        <CardComponent
          image={Packing}
          alt='PACKING'
          heading='PACKING'
          content='In line with our services, we also pack, crate and even label 
          your cargoes for you.
          We have the team to ensure you that your cargoes get the 
          correct packaging and labelling to avoid damage, breakage 
          and misplaced items. It is our goal to make sure your goods 
          are packed - properly and without error.'
          onClick={handleExpandPacking}
          expand={packing}
          aria={packing}
          collapse={packing}
        />

        <CardComponent
          image={MI}
          alt='MARINE INSURANCE'
          heading='MARINE INSURANCE'
          content='Worrying for lost and damaged assets ends with us. For a 
          small amount, we have the facility to arrange insurance 
          coverage for your cargoes.
          We ensure that from loss and damages, your cargoes are 
          protected - all wrapped up in cotton wool.'
          onClick={handleExpandmi}
          expand={mi}
          aria={mi}
          collapse={mi}
        />

        <CardComponent
          image={Fumigation}
          alt='FUMIGATION & PHYTOSANITARY SERVICES'
          heading='FUMIGATION & PHYTOSANITARY SERVICES'
          content='We can guarantee that your shipments comply 
          with pest controls and sanitary measures by most 
          countries.
          Our logistics specialists will make sure that your 
          goods are managed expertly, tidily - just the way it 
          should be.'
          onClick={handleExpandFumigation}
          expand={fumigation}
          aria={fumigation}
          collapse={fumigation}
        />
      </div>
    </div>
  )
}

export default Services
