import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import Logo from '../assets/images/logo/logo.png'
import { ReactComponent as Home } from '../assets/images/svg/home.svg'
import { ReactComponent as Services } from '../assets/images/svg/services.svg'
import { ReactComponent as AboutUs } from '../assets/images/svg/about-us.svg'
import { ReactComponent as WhatsNew } from '../assets/images/svg/whats-new.svg'
import { ReactComponent as Blogs } from '../assets/images/svg/blogs.svg'
import { ReactComponent as Contact } from '../assets/images/svg/contact.svg'
import { ReactComponent as Career } from "../assets/images/svg/careers.svg";

const Menu = () => {
  const [stickyClass, setStickyClass] = useState('')
  const [open, setOpen] = useState(false)
  const [click, setClick] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar)
    return () => window.removeEventListener('scroll', stickNavbar)
  }, [])

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY
      windowHeight > 50 ? setStickyClass('sticky-nav') : setStickyClass('')
    }
  }

  const handleClick = () => setOpen(!open)
  const handleClickNav = () => setClick(!click)

  const closeMenu = () => {
    setClick(false)
    setOpen(false)
  }

  return (
    <div className={`Menu ${stickyClass}`}>
      <div className='Menu__items'>
        <nav>
          <a href='/'>
            <img src={Logo} alt='logo' />
          </a>
          <div className='hamburger' onClick={handleClickNav}>
            {click ? <CloseIcon /> : <MenuIcon />}
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li>
              <Link
                to='home'
                spy
                smooth
                offset={70}
                duration={800}
                onClick={closeMenu}
              >
                <Home />
                Home
              </Link>
            </li>
            <li>
              <Link
                to='services'
                spy
                smooth
                offset={-50}
                duration={800}
                onClick={closeMenu}
              >
                <Services />
                Services
              </Link>
            </li>
            <li>
              <Link
                to='aboutus'
                spy
                smooth
                offset={-150}
                duration={800}
              >
                <AboutUs />
                About Us
                {open
                  ? (
                    <ExpandLess onClick={handleClick} />
                    )
                  : (
                    <ExpandMore onClick={handleClick} />
                    )}
              </Link>
              <ol>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <Link
                        to='faq'
                        spy
                        smooth
                        offset={-150}
                        duration={800}
                        onClick={closeMenu}
                      >
                        FAQ
                      </Link>
                    </ListItemButton>
                  </List>
                </Collapse>
              </ol>
            </li>
            <li>
              <Link
                to='whatsnew'
                spy
                smooth
                offset={-150}
                duration={800}
                onClick={closeMenu}
              >
                <WhatsNew />
                What's New
              </Link>
            </li>
            <li>
              <Link
                to='articles'
                spy
                smooth
                offset={-150}
                duration={800}
                onClick={closeMenu}
              >
                <Blogs />
                Blogs
              </Link>
            </li>
            <li>
              <Link
                to="careers"
                spy={true}
                smooth={true}
                offset={-100}
                duration={800}
                onClick={closeMenu}
              >
                <Career />
                Careers
              </Link>
            </li>
            <li>
              <Link
                to='contact'
                spy
                smooth
                offset={-150}
                duration={800}
                onClick={closeMenu}
              >
                <Contact />
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Menu
